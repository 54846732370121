<template>
  <div>
    其他

  </div>
</template>

<script>
export default {
  name: 'OtherPage',
  data() {
    return {
      description: '其他'

    }
  }
}
</script>
<style lang="less" scoped></style>